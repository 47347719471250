import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":"","tile":""}},[_c(VToolbar,{attrs:{"dark":"","color":"black"}},[_c(VToolbarTitle,[_vm._v("Váš účet Davines Plus")]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardText,{staticClass:"text--white text--white pa-10"},[_c(VBtn,{attrs:{"block":"","text":""},on:{"click":_vm.logout}},[_vm._v("Odhlásit se")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }