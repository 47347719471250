
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import user from './modules/user'
import auth from './modules/auth'
import cart from './modules/cart'
import rewards from './modules/rewards'
import {CLOSE_DIALOG, END_LOADER, OPEN_DIALOG, START_LOADER} from "./actions/app";

const vuexLocal = new VuexPersistence({
    key: 'persistant',
    storage: window.localStorage
});

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {
    loader: true,
    dialog: {
        show: false,
        color: 'black',
        title: '',
        text: '',
    },
}

const actions = {
    [START_LOADER]: ({commit}) => {
        commit(START_LOADER)
    },
    [END_LOADER]: ({commit}) => {
        commit(END_LOADER)
    },
    [OPEN_DIALOG]: ({commit}, payload) => {
        commit(OPEN_DIALOG, payload)
    },
    [CLOSE_DIALOG]: ({commit}) => {
        commit(CLOSE_DIALOG)
    },
}

const mutations = {
    [START_LOADER]: (state) => {
        state.loader = true;
    },
    [END_LOADER]: (state) => {
        state.loader = false;
    },
    [OPEN_DIALOG]: (state, payload) => {
        state.dialog.show = true;
        state.dialog.title = payload.title;
        state.dialog.text = payload.text;
    },
    [CLOSE_DIALOG]: (state) => {
        state.dialog.show = false;
        state.dialog.title = '';
        state.dialog.text = '';
        state.dialog.color = 'black';
    },
}


export default new Vuex.Store({
    modules: {
        user,
        auth,
        cart,
        rewards,
    },
    state: state,
    actions: actions,
    mutations: mutations,
    plugins: [vuexLocal.plugin],
    strict: debug,
})
