<template>
    <v-card class="text-center" flat>
        <v-container class="pb-0">
            <v-row>
                <v-col cols="12">
                    <h1 class="title">{{ reward.name }}</h1>
                </v-col>
                <v-col cols="12" class="pt-0 body-2">
                    {{ reward.description }}
                </v-col>
                <v-col cols="4" xs="4">
                    <div class="title text--primary">{{ reward.price | currency }}</div>
                </v-col>
                <v-col cols="5" sm="5">
                    <v-text-field
                            v-model="quantity"
                            outlined
                            rouneded
                            type="number"
                            dense
                            hide-details
                            prepend-icon="mdi-minus-circle"
                            append-outer-icon="mdi-plus-circle"
                            @click:append-outer="plus"
                            @click:prepend="minus"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3">
                    <v-btn fab small dark rounded class="mx-auto mb-2" @click.stop="addToOrder">
                    <v-badge v-model="badgeShow" right color="purple">
                        <template v-slot:badge>
                            <span>{{ cartCount }}</span>
                        </template>
                            <v-icon>mdi-basket</v-icon>
                    </v-badge>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="px-0 pb-0">
                    <v-img max-width="100vw" class="pb-2" :src="imagePath"></v-img>
                </v-col>
            </v-row>
        </v-container>

    </v-card>
</template>

<script>

    import {ADD_TO_CART} from '@/store/actions/cart'

    export default {
        name: 'Reward',
        data: () => ({
            quantity: 1,
        }),
        props: {
            id: {
                required: true,
                type: Number,
            }
        },
        computed: {
            reward() {
                return this.$store.getters.getReward(this.id)
            },
            badgeShow() {
                return this.cartCount > 0
            },
            rewards() {
                return this.$store.getters.getRewards
            },
            cartCount() {
                return this.$store.getters.getCartRewardCount(this.id)
            },
            imagePath() {
                return process.env.VUE_APP_IMAGE_PATH + 'large/' + this.reward.image;
            }
        },
        methods: {
            addToOrder() {
                this.$store.dispatch(ADD_TO_CART, {quantity: Number(this.quantity), id: this.id}).then(() => {
                    this.quantity = 1;
                });
            },
            plus() {
                this.quantity++;
            },
            minus() {
                this.quantity--;
            }
        }
    };
</script>
