<template>
    <layout>
        <Reward v-bind:id="id"/>
    </layout>
</template>

<script>
    import Layout from '@/layouts/User';
    import Reward from '@/components/Reward'

    export default {
        name: 'rewards',
        props: {
            id: {
                required: true,
                type: Number,
            }
        },
        components: {
            Layout,
            Reward
        }
    }
</script>
