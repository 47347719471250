import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c(VCard,{attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"text--white text--white pa-10"},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.forgot.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"E-mail","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"mb-4"},[_vm._v("Na zadaný registrační email bude zaslán odkaz pro obnovení hesla.")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c(VSpacer),_c(VBtn,{staticClass:"mt-3",attrs:{"dark":"","block":"","x-large":""},on:{"click":_vm.forgot}},[_vm._v("Obnovení hesla")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }