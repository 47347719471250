<template>
    <v-container>
        <h1 class="text-center headline py-5">Odměny</h1>
        <v-list three-line>
            <reward-item v-for="(reward) in rewards" :key="reward.id" v-bind="reward"></reward-item>
        </v-list>
        <div class="text-center mt-5" v-show="cartCount > 0">
            <v-btn dark large class="mx-auto" to="/cart">
                Zobrazit košík
                <v-icon right>mdi-basket</v-icon>
            </v-btn>
        </div>
    </v-container>
</template>

<script>

    import RewardItem from '@/components/RewardItem';
    import {mapGetters} from "vuex";

    export default {
        name: 'Rewards',
        components: {
            RewardItem,
        },
        computed: {
            ...mapGetters({
                rewards: 'getRewards',
                cartCount: 'getCartCount',
            }),
        },
    };
</script>
