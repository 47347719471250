import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"cart-item align-center"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-1 font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.reward.name)}})]),_c(VCol,{staticClass:"py-0",attrs:{"cols":"2"}},[_c(VImg,{attrs:{"max-width":"50px","src":_vm.imagePath}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"outlined":"","rouneded":"","type":"number","dense":"","hide-details":""},on:{"change":_vm.onQuantityChange},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.reward.price * _vm.quantity))+" "),_c('br')]),_c(VCol,{staticClass:"py-0",attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.remove}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }