import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"bg-login hidden-sm-and-down",attrs:{"cols":"12","md":"6","lg":"6"}}),_c(VCol,{staticClass:"pb-12 mb-6",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"d-flex flex-column fill-height"},[_c(VAppBar,{staticClass:"flex-grow-0 fill-width",attrs:{"app":_vm.appBar,"elevate-on-scroll":""}},[_c(VImg,{attrs:{"max-width":"100px","src":require("../assets/logo-black.png")},on:{"click":_vm.toHome}}),_c(VSpacer),_c('mini-cart'),_c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('settings',{on:{"onClose":_vm.closeSettings}})],1)],1),_c('div',{staticClass:"d-flex flex-grow-1 flex-column justify-center fill-width"},[_vm._t("default")],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }