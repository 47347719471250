<template>
    <layout>
        <v-card flat tile>
            <v-card-text class="text--white text--white pa-10">
                <v-form @submit.prevent="login">
                    <v-text-field
                            label="E-mail"
                            append-icon="mdi-email"
                            v-model="email"
                            type="email"
                    />

                    <v-text-field
                            id="password"
                            append-icon="mdi-lock"
                            label="Password"
                            v-model="password"
                            type="password"
                    />
                    <div class="d-flex flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn to="/lost-password" text x-small class="mt-3">zapoměli jste heslo?</v-btn>
                        <v-btn dark block x-large class="mt-3" type="submit">Přihlásit se</v-btn>
                        <v-btn to="/signup" text block x-large class="mt-3">Registrace</v-btn>
                        <install></install>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </layout>
</template>
<script>
    import Layout from '@/layouts/Guest';
    import {AUTH_REQUEST} from '@/store/actions/auth'
    import Install from "@/components/Install";

    export default {
        name: 'login',
        components: {
            Layout,
            Install,
        },
        data() {
            return {
                email: null,
                password: null,
            }
        },
        methods: {
            login() {
                this.$store.dispatch(AUTH_REQUEST, {email: this.email, password: this.password}).then(() => {
                  this.$root.snackbar.show({message: 'Přihlášení proběhlo úspěšně.'})
                    this.$router.push('/')
                }).catch(() => {
                  this.$root.snackbar.show({message: 'Chyba přihlášení, zkontrolujte prosím přihlašovací údaje.', color: "error", icon: "mdi-close"})
                })
            }
        },
    }
</script>
