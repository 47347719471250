<template>
    <v-app>
        <snack-bar ref="snackbar"/>
        <v-btn
                v-if="$routerHistory.hasPrevious() && showBackButton"
                color="pink"
                :to="{ path: $routerHistory.previous().path }"
                dark fixed right bottom fab>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-main>
            <v-container class="fill-height pa-0" fluid>
                <!--<transition name="fade">-->
                <router-view/>
                <!--</transition>-->
            </v-container>
        </v-main>
        <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog
                :overlay-color="dialog.color"
                v-model="dialog.show"
                max-width="290"
        >
            <v-card>
                <v-card-title class="headline">{{ dialog.title }}</v-card-title>
                <v-card-text>
                    {{ dialog.text }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            dark
                            large
                            class="mx-auto"
                            @click="closeDialog"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackWithButtons" :timeout="timeout" bottom vertical>
            <div>{{ snackWithBtnText }}
                <v-btn icon fab absolute top right x-small @click="snackWithButtons = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div>
                <v-btn dark color="info" @click.native="refreshApp">{{ snackBtnText }}</v-btn>
            </div>
        </v-snackbar>
    </v-app>
</template>

<script>
    import {END_LOADER, CLOSE_DIALOG} from "./store/actions/app";
    import snackBar from '@/components/Snackbar'

    export default {
        name: 'App',
        components:{
          snackBar
        },
        data: () => ({
            refreshing: false,
            registration: null,
            snackBtnText: '',
            transitionName: '',
            snackWithBtnText: '',
            snackWithButtons: false,
            timeout: -1,
            installBtn: false,
            installer: false,
            installPrompt: null,
        }),
        mounted() {
            this.$store.dispatch(END_LOADER);
            this.$root.snackbar = this.$refs.snackbar
        },
        created() {
            document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        },
        computed: {
            showBackButton() {
                return this.$route.name !== 'Home' && this.$route.name !== 'Login';
            },
            loader() {
                return this.$store.state.loader;
            },
            dialog() {
                return this.$store.state.dialog;
            }
        },
        methods: {
            showRefreshUI(e) {
                this.registration = e.detail;
                this.snackBtnText = 'Aktualizovat';
                this.snackWithBtnText = 'K dispozici je nová verze!';
                this.snackWithButtons = true;
            },
            'refreshApp'() {
                this.snackWithButtons = false;
                if (!this.registration || !this.registration.waiting) {
                    return;
                }
                this.registration.waiting.postMessage('skipWaiting');
            },
            closeDialog() {
                this.$store.dispatch(CLOSE_DIALOG);
            },
        },
    };
</script>
