<template>
    <layout>
        <Rewards/>
    </layout>
</template>

<script>
    import Layout from '@/layouts/User';
    import Rewards from '@/components/Rewards'

    export default {
        name: 'rewards',
        components: {
            Layout,
            Rewards
        }
    }
</script>
