import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"text-center",attrs:{"flat":""}},[_c(VContainer,{staticClass:"pb-0"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.reward.name))])]),_c(VCol,{staticClass:"pt-0 body-2",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.reward.description)+" ")]),_c(VCol,{attrs:{"cols":"4","xs":"4"}},[_c('div',{staticClass:"title text--primary"},[_vm._v(_vm._s(_vm._f("currency")(_vm.reward.price)))])]),_c(VCol,{attrs:{"cols":"5","sm":"5"}},[_c(VTextField,{attrs:{"outlined":"","rouneded":"","type":"number","dense":"","hide-details":"","prepend-icon":"mdi-minus-circle","append-outer-icon":"mdi-plus-circle"},on:{"click:append-outer":_vm.plus,"click:prepend":_vm.minus},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1),_c(VCol,{attrs:{"cols":"3","sm":"3"}},[_c(VBtn,{staticClass:"mx-auto mb-2",attrs:{"fab":"","small":"","dark":"","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.addToOrder.apply(null, arguments)}}},[_c(VBadge,{attrs:{"right":"","color":"purple"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.cartCount))])]},proxy:true}]),model:{value:(_vm.badgeShow),callback:function ($$v) {_vm.badgeShow=$$v},expression:"badgeShow"}},[_c(VIcon,[_vm._v("mdi-basket")])],1)],1)],1),_c(VCol,{staticClass:"px-0 pb-0",attrs:{"cols":"12"}},[_c(VImg,{staticClass:"pb-2",attrs:{"max-width":"100vw","src":_vm.imagePath}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }