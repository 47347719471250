import {ADD_TO_CART, UPDATE_CART_ITEM, REMOVE_CART_ITEM, CLEAR_CART, SEND_ORDER} from '../actions/cart'
import Vue from 'vue'
import apiCall from "../../utils/api";
import {USER_REQUEST} from "../actions/user";
import {forEach, find} from "lodash";

const state = {
    items: {},
    status: {},
};

const getters = {
    getCartItems: state => state.items,
    getCartCount: state => {
        let count = 0;
        forEach(state.items, function (quantity) {
            count = count + quantity;
        });
        return count;
    },
    getCartSum: (state, getters, rootState) => {
        let sum = 0;
        let rewards = rootState.rewards.items;
        forEach(state.items, function (quantity, id) {
            let reward = find(rewards, {'id': Number(id)});
            sum = sum + (quantity * reward.price);
        });
        return sum;
    },
    getCartRewardCount: (state) => {
        return (id) => {
            return state.items[id] ? state.items[id] : null;
        }
    },
    isOverdrawn: (state, getters) => {
        return (getters.getBalance - getters.getCartSum) < 0;
    },
}

const actions = {
    [ADD_TO_CART]: ({state, commit}, payload) => {
        if (payload.quantity === 0)
            return;

        if (state.items[payload.id] === undefined) {
            if (payload.quantity > 0)
                commit(ADD_TO_CART, payload)
        } else {
            payload.quantity = state.items[payload.id] + Number(payload.quantity);
            if (payload.quantity > 0) {
                commit(UPDATE_CART_ITEM, payload)
            } else {
                commit(REMOVE_CART_ITEM, payload.id)
            }
        }
    },
    [UPDATE_CART_ITEM]: ({commit}, payload) => {
        if (payload.quantity <= 0)
            payload.quantity = 1;

        commit(UPDATE_CART_ITEM, payload);
    },
    [REMOVE_CART_ITEM]: ({commit}, id) => {
        commit(REMOVE_CART_ITEM, id);
    },
    [CLEAR_CART]: ({commit}) => {
        commit(CLEAR_CART);
    },
    [SEND_ORDER]: ({state, dispatch}) => {
        return new Promise((resolve, reject) => {
            apiCall({url: 'order', method: 'POST', data: {cart: state.items}})
                .then((resp) => {
                    dispatch(USER_REQUEST);
                    resolve(resp)
                })
                .catch((err) => {
                    // commit(AUTH_ERROR, err)
                    reject(err)
                })
        })
    }
}

const mutations = {
    [ADD_TO_CART]: (state, payload) => {
        Vue.set(state.items, payload.id, Number(payload.quantity));
    },
    [UPDATE_CART_ITEM]: (state, payload) => {
        Vue.set(state.items, payload.id, Number(payload.quantity));
    },
    [REMOVE_CART_ITEM]: (state, id) => {
        Vue.delete(state.items, id);
    },
    [CLEAR_CART]: (state) => {
        state.items = {};
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
