<template>
    <v-row class="fill-height" no-gutters>
        <v-col cols="12" md="6" lg="6" class="bg-login hidden-sm-and-down"></v-col>
        <v-col cols="12" md="6" lg="6" class="pb-12 mb-6">
            <div class="d-flex flex-column fill-height">
                <v-app-bar :app="appBar" elevate-on-scroll class="flex-grow-0 fill-width">
                    <v-img @click="toHome" max-width="100px" src="../assets/logo-black.png"></v-img>
                    <v-spacer></v-spacer>
                    <mini-cart></mini-cart>
                    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <settings @onClose="closeSettings"></settings>
                    </v-dialog>
                </v-app-bar>
                <div class="d-flex flex-grow-1 flex-column justify-center fill-width">
                    <slot></slot>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>

    import Settings from '@/components/Settings';
    import MiniCart from '@/components/MiniCart';

    export default {
        name: 'guest_layout',
        components: {
            Settings,
            MiniCart,
        },
        data() {
            return {
                dialog: false,
            }
        },
        computed: {
            appBar() {
                let appBar;

                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                    case 'sm':
                        appBar = true;
                        break;
                    default:
                        appBar = false;
                }
                return appBar;
            },
        },
        methods: {
            closeSettings() {
                this.dialog = false;
            },
            toHome() {
                this.$router.push('/');
            }
        }
    }
</script>
