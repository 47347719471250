<template>
    <v-row class="fill-height" no-gutters>
        <v-col cols="12" md="6" lg="6" class="bg-login hidden-sm-and-down">
        </v-col>
        <v-col cols="12" md="6" lg="6" class="align-self-center pb-12 mb-6">
            <div class="px-10 pt-10">
                <v-img max-width="300px" src="../assets/logo-black.png"></v-img>
                <h3 class="text-center">plus</h3>
            </div>
            <slot></slot>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        name: 'guest_layout',
    }
</script>
