<template>
    <layout>
        <Home/>
    </layout>
</template>

<script>
    import Layout from '@/layouts/User';
    import Home from '@/components/Home'

    export default {
        name: 'home',
        components: {
            Layout,
            Home
        }
    }
</script>
