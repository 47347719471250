import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c(VCard,{attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"text--white text--white pa-10"},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"E-mail","type":"text","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"label":"Nové heslo","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"d-flex flex-wrap"},[_c(VSpacer),_c(VBtn,{staticClass:"mt-3",attrs:{"dark":"","block":"","x-large":""},on:{"click":_vm.reset}},[_vm._v("Nastavení hesla")]),_c(VBtn,{staticClass:"mt-3",attrs:{"to":"/login","text":"","block":"","x-large":""}},[_vm._v("Zpět")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }