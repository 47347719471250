<template>
    <layout>
        <transactions-component></transactions-component>
    </layout>
</template>

<script>
    import Layout from '@/layouts/User';
    import TransactionsComponent from '@/components/Transactions'

    export default {
        name: 'history',
        components: {
            Layout,
            TransactionsComponent
        }
    }
</script>
