<template>
        <layout>
                <Cart/>
        </layout>
</template>

<script>
    import Layout from '@/layouts/User';
    import Cart from '@/components/Cart'

    export default {
        name: 'cart',
        components: {
            Layout,
            Cart
        }
    }
</script>
