import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,{on:{"click":_vm.detail}},[_c(VListItemAvatar,{attrs:{"tile":"","height":"60","width":"60"}},[_c(VImg,{attrs:{"src":_vm.imagePath(_vm.image)}})],1),_c(VListItemContent,[_c('div',{staticClass:"mb-1 font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.name)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(_vm.description)}})],1),_c(VListItemAction,[_c(VBtn,{staticClass:"mb-2",attrs:{"fab":"","small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.addToOrder.apply(null, arguments)}}},[_c(VBadge,{attrs:{"right":"","color":"pink"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.cartCount))])]},proxy:true}]),model:{value:(_vm.badgeShow),callback:function ($$v) {_vm.badgeShow=$$v},expression:"badgeShow"}},[_c(VIcon,[_vm._v("mdi-basket")])],1)],1),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.price))+" ")],1)],1),_c(VDivider,{attrs:{"inset":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }