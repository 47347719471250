<template>
    <tr>
        <td>
            <span class="mb-0 font-weight-bold">{{ name }}</span><br/>
            <span class="body-2">{{ description }}</span>
        </td>
        <td class="text-center">
          <span v-if="type === 'reward'"></span><span v-else>{{ date | moment("DD.MM.YYYY") }}</span>
        </td>
        <td class="text-center">
          {{ quantity }}
        </td>
        <td class="text-right">
            {{ balance | currency }}
        </td>
    </tr>
</template>

<script>

    export default {
        name: 'TransactionItem',
        data: () => ({}),
        props: {
            id: {
                type: [Number, String],
            },
            name: {
                type: String,
            },
            description: {
                type: String,
            },
            order_id: {
                type: [Number, null],
            },
            type: {
                type: String,
            },
            date: {
              type: [String, null],
            },
            quantity: {
                type: [Number, null],
            },
            balance: {
                type: Number,
            },
        },
        methods: {
            detail() {
                // this.$router.push({name: 'RewardDetail', params: {id: this.id}});
            },
        }
    };
</script>
