import Vue from 'vue'
import Router from 'vue-router'
import VueRouterBackButton from 'vue-router-back-button'
import Home from '@/views/Home'
import Rewards from '@/views/Rewards'
import RewardList from '@/views/RewardList'
import Reward from '@/views/Reward'
import Cart from '@/views/Cart'
import Transactions from '@/views/Transactions'
import SignUp from '@/views/SignUp'
import Login from '@/views/Login'
import LostPassword from '@/views/LostPassword'
import store from '../store'
import Reset from "@/views/Reset";

Vue.use(Router)


const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/login')
}

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            alias: '/index.html',
            name: 'Home',
            component: Home,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/cart',
            name: 'Cart',
            component: Cart,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/transactions',
            name: 'Transactions',
            component: Transactions,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/rewards',
            component: Rewards,
            children: [
                {
                    path: '/',
                    name: 'Rewards',
                    component: RewardList,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: ':id',
                    name: 'RewardDetail',
                    component: Reward,
                    props: (route) => ({id: Number(route.params.id)}),
                    beforeEnter: ifAuthenticated,
                },
            ],
        },
        // {
        //     path: '/account',
        //     name: 'Account',
        //     component: Account,
        //     beforeEnter: ifAuthenticated,
        // },
        {
            path: '/lost-password',
            name: 'LostPassword',
            component: LostPassword,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/signup',
            name: 'SignUp',
            component: SignUp,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/reset',
            name: 'Reset',
            component: Reset,
            props: (route) => ({token: route.query.token, email: route.query.email}),
            beforeEnter: ifNotAuthenticated,
        },
    ],
})

Vue.use(VueRouterBackButton, {
    router,
    ignoreRoutesWithSameName: true,
})

router.beforeEach((to, from, next) => {
    next();
});

export default router
