<template>
    <v-card flat tile>
        <v-toolbar dark color="black">
            <v-toolbar-title>Váš účet Davines Plus</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn icon dark @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="text--white text--white pa-10">
            <!--<v-btn dark text @click="dialog = false">Save</v-btn>-->
            <v-btn block text @click="logout">Odhlásit se</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>

    import {AUTH_LOGOUT} from '@/store/actions/auth'
    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'HelloWorld',
        data: () => ({}),
        computed: {
            ...mapGetters([
                'getProfile',
                'isAuthenticated',
                'isProfileLoaded'
            ]),
            ...mapState({
                name: state => `${state.user.profile.title} ${state.user.profile.name}`,
                status: state => state.auth.status,
            })
        },
        methods: {
            close() {
                this.$emit('onClose');
            },
            logout() {
                this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
            }
        }
    };
</script>
