<template>
    <v-container>
        <h1 v-if="cartCount > 0" class="headline text-center mb-4">Obsah košíku</h1>
        <div v-if="cartCount > 0">
            <div>
                <cart-item v-for="(quantity, id) in cartItems" :key="id" v-bind:quantity="quantity"
                           v-bind:id="id"></cart-item>
            </div>
            <div class="pt-6 text-right">
                <div class="subtitle-2">Objednávka celkem: {{ cartSum | currency }}</div>
                <div class="subtitle-2 font-weight-regular">Peněženka: {{ balance | currency }}</div>
                <div class="title">Zůstatek: {{ balance - cartSum | currency }}</div>
            </div>
            <div class="mt-4">
                <v-alert v-if="isOverdrawn" type="error">
                    Částka objednávky přesahuje částku peněženky.
                </v-alert>
                <v-btn block x-large v-show="!isOverdrawn" :disabled="isOverdrawn" dark @click="order" class="py-8">
                    <div>Zaslat k vyřízení<br/><span class="caption">vašemu obchodnímu zástupci</span></div>
                </v-btn>
                <v-btn block text class="mt-2" to="/rewards">Vybrat další odměny</v-btn>
                <v-btn block text small class="mt-8" @click="clearCart">Vyprázdnit košík</v-btn>
            </div>
        </div>
        <div v-if="cartCount <= 0" class="text-center title">
            <div class="my-5">Váš košík je prázdný</div>
            <v-btn dark x-large block to="/rewards">Vybrat odměnu</v-btn>
        </div>
    </v-container>
</template>

<script>

import {CLEAR_CART} from '@/store/actions/cart' // REMOVE_CART_ITEM

import CartItem from './CartItem';
import {mapGetters} from "vuex";
import {SEND_ORDER} from "@/store/actions/cart";
import {START_LOADER, END_LOADER, OPEN_DIALOG} from "@/store/actions/app";

export default {
    name: 'Cart',
    components: {
        CartItem,
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            cartCount: 'getCartCount',
            cartItems: 'getCartItems',
            cartSum: 'getCartSum',
            balance: 'getBalance',
            isOverdrawn: 'isOverdrawn',
        }),
    },
    methods: {
        order() {
            this.$store.dispatch(START_LOADER);
            this.$store.dispatch(SEND_ORDER).then((resp) => {
                this.clearCart();
                this.$store.dispatch(OPEN_DIALOG, {
                    title: resp.data.title,
                    text: resp.data.text,
                });
                this.$router.push('/');
            }).finally(() => {
                this.$store.dispatch(END_LOADER);
            });
        },
        clearCart() {
            this.$store.dispatch(CLEAR_CART);
        },
    }
};
</script>
