import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c(VCard,{attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"text--white text--white pa-10"},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"E-mail","append-icon":"mdi-email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"id":"password","append-icon":"mdi-lock","label":"Password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"d-flex flex-wrap"},[_c(VSpacer),_c(VBtn,{staticClass:"mt-3",attrs:{"to":"/lost-password","text":"","x-small":""}},[_vm._v("zapoměli jste heslo?")]),_c(VBtn,{staticClass:"mt-3",attrs:{"dark":"","block":"","x-large":"","type":"submit"}},[_vm._v("Přihlásit se")]),_c(VBtn,{staticClass:"mt-3",attrs:{"to":"/signup","text":"","block":"","x-large":""}},[_vm._v("Registrace")]),_c('install')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }