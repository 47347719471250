<template>
    <layout>
        <v-card flat tile>
            <v-card-text class="text--white text--white pa-10">
                <v-form @submit.prevent="forgot">
                    <v-text-field
                            label="E-mail"
                            v-model="email"
                            type="text"
                    />
                    <div class="mb-4">Na zadaný registrační email bude zaslán odkaz pro obnovení hesla.</div>
                    <div class="d-flex flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn dark block x-large class="mt-3" @click="forgot">Obnovení hesla</v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </layout>
</template>
<script>
    import Layout from '@/layouts/Guest';
    import {AUTH_FORGOT} from '@/store/actions/auth'

    export default {
        name: 'login',
        components: {
          Layout,
        },
        data() {
            return {
                email: '',
            }
        },
        methods: {
          forgot: function () {
                const {email} = this
                this.$store.dispatch(AUTH_FORGOT, {email}).then(() => {
                  this.$root.snackbar.show({message: 'Email pro obnovení hesla byl zaslán.'})
                  this.$router.push('/login')
                }).catch(()=> {
                  this.$root.snackbar.show({message: 'Chyba při obnovení hesla, zkontrolujte prosím emailovou adresu.', color: "error", icon: "mdi-close"})
                })
            }
        },
    }
</script>
