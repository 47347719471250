<template>
  <layout>
    <v-card flat tile>
      <v-card-text class="text--white text--white pa-10">
        <v-form @submit.prevent="reset">
          <v-text-field
              label="E-mail"
              v-model="email"
              type="text"
              disabled
          />
          <v-text-field
              label="Nové heslo"
              v-model="password"
              type="password"
          />
          <div class="d-flex flex-wrap">
            <v-spacer></v-spacer>
            <v-btn dark block x-large class="mt-3" @click="reset">Nastavení hesla</v-btn>
            <v-btn to="/login" text block x-large class="mt-3">Zpět</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </layout>
</template>
<script>
import Layout from '@/layouts/Guest';
import {AUTH_REQUEST, AUTH_RESET} from '@/store/actions/auth'

export default {
  name: 'login',
  components: {
    Layout,
  },
  props: {
    email: String,
    token: String,
  },
  data() {
    return {
      password: '',
    }
  },
  methods: {
    reset: function () {
      this.$store.dispatch(AUTH_RESET, {email: this.email, token: this.token, password: this.password, password_confirmation: this.password}).then((response) => {
          if (response.msg === 'passwords.reset') {
              this.$root.snackbar.show({message: 'Heslo bylo nastaveno.'});
              this.$store.dispatch(AUTH_REQUEST, {email: this.email, password: this.password}).then(() => {
                  this.$router.push('/')
              });      
          } else if (response.msg === 'passwords.token') {
              this.$root.snackbar.show({message: 'Chybný token pro reset hesla.', color: "error", icon: "mdi-close"});
          } else {
              this.$root.snackbar.show({message: 'Chyba při nastavení hesla.', color: "error", icon: "mdi-close"})
          }
      }).catch(() => {
        this.$root.snackbar.show({message: 'Chyba při nastavení hesla.', color: "error", icon: "mdi-close"})
      })
    }
  },
}
</script>
