<template>
    <layout>
        <v-card flat tile>
            <v-card-text class="text--white text--white pa-10">
                <v-form @submit.prevent="login">

                    <v-text-field
                            label="Přihlašovací e-mail"
                            v-model="email"
                            :disabled="emailDisabled"
                            type="email"
                    />
                    <v-text-field
                            label="Jméno a příjmení"
                            v-model="name"
                            type="text"
                    />
                    <v-text-field
                        label="Heslo"
                        v-model="password"
                        type="password"
                    />
                    <v-text-field
                            label="Telefon"
                            v-model="phone"
                            type="text"
                    />
                    <v-text-field
                        label="Aktivační kód"
                        v-model="code"
                        :disabled="codeDisabled"
                        type="text"
                    />
                    <div class="d-flex flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn dark block x-large class="mt-3" @click="login">Zaregistrovat se</v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </layout>
</template>
<script>
    import Layout from '@/layouts/Guest';
    import {AUTH_REQUEST, AUTH_SIGNUP} from '@/store/actions/auth'

    export default {
        name: 'login',
        components: {
            Layout,
        },
        created() {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('email')) {
                this.email = urlParams.get('email');
                this.emailDisabled = true;
            }
            if (urlParams.has('code')) {
                this.code = urlParams.get('code');
                this.codeDisabled = true;
            }
        },
        data() {
            return {
                email: '',
                emailDisabled: false,
                password: '',
                code: '',
                codeDisabled: false,
                name: '',
                phone: '',
            }
        },
        methods: {
            login: function () {
                const {email, password, name, code} = this
                this.$store.dispatch(AUTH_SIGNUP, {email: email, name: name, password: password, code: code}).then(() => {
                  this.$store.dispatch(AUTH_REQUEST, {email: email, password: password}).then(() => {
                    this.$root.snackbar.show({message: 'Aktivace proběhla úspěšně.'})
                    this.$router.push('/')
                  });
                }).catch(() => {
                  this.$root.snackbar.show({message: "Aktivace se nezdařila. Kontaktujte vašeho obchodního zástupce.", color: "error", icon: "mdi-close"})
                })
            }
        },
    }
</script>
