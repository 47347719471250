<template>
    <v-btn text class="mx-0" :color="color" to="/cart">
        <v-icon left>mdi-basket</v-icon>
        <span>{{ getCartCount }}</span>
    </v-btn>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: 'MiniCart',
        data: () => ({}),
        computed: {
            ...mapGetters([
                'getCartCount',
                'isOverdrawn',
            ]),
            color() {
                return this.isOverdrawn ? 'error' : 'secondary';
            }
        },
    };
</script>
