import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('snack-bar',{ref:"snackbar"}),(_vm.$routerHistory.hasPrevious() && _vm.showBackButton)?_c(VBtn,{attrs:{"color":"pink","to":{ path: _vm.$routerHistory.previous().path },"dark":"","fixed":"","right":"","bottom":"","fab":""}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1):_vm._e(),_c(VMain,[_c(VContainer,{staticClass:"fill-height pa-0",attrs:{"fluid":""}},[_c('router-view')],1)],1),_c(VOverlay,{attrs:{"value":_vm.loader}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VDialog,{attrs:{"overlay-color":_vm.dialog.color,"max-width":"290"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.dialog.text)+" ")]),_c(VCardActions,[_c(VBtn,{staticClass:"mx-auto",attrs:{"dark":"","large":""},on:{"click":_vm.closeDialog}},[_vm._v(" Ok ")])],1)],1)],1),_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"bottom":"","vertical":""},model:{value:(_vm.snackWithButtons),callback:function ($$v) {_vm.snackWithButtons=$$v},expression:"snackWithButtons"}},[_c('div',[_vm._v(_vm._s(_vm.snackWithBtnText)+" "),_c(VBtn,{attrs:{"icon":"","fab":"","absolute":"","top":"","right":"","x-small":""},on:{"click":function($event){_vm.snackWithButtons = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('div',[_c(VBtn,{attrs:{"dark":"","color":"info"},nativeOn:{"click":function($event){return _vm.refreshApp.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.snackBtnText))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }