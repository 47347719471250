import axios from "axios";

const apiCall = ({url, method = 'GET', ...args}) => new Promise((resolve, reject) => {
    try {
        axios({
            method: method,
            url: process.env.VUE_APP_API_URL + url,
            data: args.data
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            reject(new Error(err))
        })
    } catch (err) {
        reject(new Error(err))
    }
})

export default apiCall
