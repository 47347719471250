import {USER_REQUEST, USER_ERROR, USER_SUCCESS} from '../actions/user'
import apiCall from '@/utils/api'
import Vue from 'vue'
import {AUTH_LOGOUT} from '../actions/auth'
import {REWARDS_SET} from "../actions/rewards";

const state = {
    status: '',
    profile: {
        name: '',
        status: 0,
        balance: 0,
        transactions: [],
    }
};

const getters = {
    getProfile: state => state.profile,
    getBalance: state => state.profile.balance,
    getTransactions: state => state.profile.transactions,
    isProfileLoaded: state => !!state.profile.name,
}

const actions = {
    [USER_REQUEST]: ({commit, dispatch}) => {

        commit(USER_REQUEST)
        
        apiCall({url: 'me?_t=' + new Date().getTime()})
            .then(resp => {
                commit(USER_SUCCESS, resp)
                dispatch(REWARDS_SET, resp.rewards)
            })
            .catch(() => {
                commit(USER_ERROR)
                window.localStorage.clear();
                localStorage.removeItem('user-token')
                localStorage.removeItem('persistant')
                window.localStorage.clear();
                dispatch(AUTH_LOGOUT)
            })
    },
}

const mutations = {
    [USER_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = 'success'
        Vue.set(state, 'profile', resp)
    },
    [USER_ERROR]: (state) => {
        state.status = 'error'
    },
    [AUTH_LOGOUT]: (state) => {
        state.profile = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
