<template>
    <div>
        <h1 class="text-center headline py-5">Přehled historie peněženky</h1>
        <v-simple-table>
            <thead>
            <tr>
                <th>Název</th>
                <th class="text-center">Datum</th>
                <th class="text-center">Barev</th>
                <th class="text-right">Částka</th>
            </tr>
            </thead>
            <tbody>
            <transaction-item v-for="(transaction) in transactions" :key="transaction.id"
                              v-bind="transaction"></transaction-item>
            </tbody>
            <tfoot>
            <tr class="body-1 font-weight-bold text-right">
                <td colspan="4" class="text-right">Zůstatek: {{ profile.balance | currency }}</td>
            </tr>
            </tfoot>
        </v-simple-table>
    </div>
</template>

<script>

    import TransactionItem from '@/components/TransactionItem';
    import {mapGetters} from "vuex";

    export default {
        name: 'Transactions',
        components: {
            TransactionItem,
        },
        computed: {
            ...mapGetters({
                transactions: 'getTransactions',
                profile: 'getProfile',
            }),
        },
    };
</script>
