<template>
    <v-btn v-show="installBtn" text block small @click="install">Instalovat aplikaci</v-btn>
</template>

<script>

    export default {
        name: 'Install',
        data: () => ({
            installBtn: false,
            installer: false,
            installPrompt: null,
        }),
        created() {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                this.installPrompt = e;
                this.showInstall();
            });
        },
        methods: {
            showInstall() {
                this.installBtn = true;
            },
            hideInstall() {
                this.installBtn = false;
            },
            install() {
                this.hideInstall();
                this.installPrompt.prompt();
                this.installPrompt.userChoice.then( () => {
                    // if (result.outcome === 'accepted') {
                    //
                    // } else {
                    //
                    // }
                    this.installPrompt = null;
                })
            }
        }
    };
</script>
