import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c(VCard,{attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"text--white text--white pa-10"},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Přihlašovací e-mail","disabled":_vm.emailDisabled,"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"label":"Jméno a příjmení","type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{attrs:{"label":"Heslo","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{attrs:{"label":"Telefon","type":"text"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c(VTextField,{attrs:{"label":"Aktivační kód","disabled":_vm.codeDisabled,"type":"text"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{staticClass:"d-flex flex-wrap"},[_c(VSpacer),_c(VBtn,{staticClass:"mt-3",attrs:{"dark":"","block":"","x-large":""},on:{"click":_vm.login}},[_vm._v("Zaregistrovat se")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }