<template>
    <v-row class="cart-item align-center">
        <v-col cols="12" class="py-0">
            <div v-html="reward.name" class="mb-1 font-weight-bold"></div>
        </v-col>
        <v-col cols="2" class="py-0">
            <v-img max-width="50px" :src="imagePath"></v-img>
        </v-col>
        <v-col cols="3" class="py-0">
            <v-text-field
                    v-model="quantity"
                    outlined
                    rouneded
                    type="number"
                    dense
                    hide-details
                    @change="onQuantityChange"
            ></v-text-field>
        </v-col>
        <v-col cols="5" class="py-0">
            {{ reward.price * quantity | currency }} <br/>
        </v-col>
        <v-col cols="2" class="py-0">
            <v-btn icon @click="remove">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

    import {UPDATE_CART_ITEM, REMOVE_CART_ITEM} from '@/store/actions/cart'

    export default {
        name: 'Cart',
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data: () => ({}),
        computed: {
            quantity: {
                get() {
                    return this.$store.state.cart.items[this.id]
                },
                set(quantity) {
                    return this.$store.dispatch(UPDATE_CART_ITEM, {id: this.id, quantity: quantity})
                }
            },
            reward() {
                return this.$store.getters.getReward(Number(this.id))
            },
            imagePath() {
                return process.env.VUE_APP_IMAGE_PATH + 'large/' + this.reward.image;
            },
        },
        methods: {
            onQuantityChange() {
                // if (Number(val) < 1)
                //     this.$store.dispatch(UPDATE_CART_ITEM, {id: this.id, quantity: 1})
            },
            remove() {
                return this.$store.dispatch(REMOVE_CART_ITEM, this.id);
            }
        }
    };
</script>
