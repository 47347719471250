import {REWARDS_SET} from '../actions/rewards'
import Vue from 'vue'
import find from "lodash/find";

const state = {
    items: []
};

const getters = {
    getRewards: state => state.items,
    getReward: (state) => {
        return (id) => {
            return find(state.items, {'id': id});
        }
    },
}

const actions = {
    [REWARDS_SET]: ({commit}, payload) => {
        commit(REWARDS_SET, payload)
    },
}

const mutations = {
    [REWARDS_SET]: (state, items) => {
        Vue.set(state, 'items', items)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
