import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import VueCurrencyFilter from 'vue-currency-filter'
import VueLodash from 'vue-lodash'
import VueMoment from 'vue-moment'

Vue.use(VueLodash)

import './sass/main.scss'

Vue.use(VueMoment)

Vue.use(VueCurrencyFilter, {
    symbol: 'Kč',
    thousandsSeparator: ' ',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true
});

Vue.config.productionTip = false
Vue.prototype.$http = Axios;
const accessToken = localStorage.getItem('user-token');
if (accessToken) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
}

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
