<template>
    <div>
        <v-list-item @click="detail">
            <v-list-item-avatar tile height="60" width="60">
                <v-img :src="imagePath(image)"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <div v-html="name" class="mb-1 font-weight-bold"></div>
                <v-list-item-subtitle v-html="description"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-btn fab small dark class="mb-2" @click.stop="addToOrder">
                    <v-badge v-model="badgeShow" right color="pink">
                        <template v-slot:badge>
                            <span>{{ cartCount }}</span>
                        </template>
                        <v-icon>mdi-basket</v-icon>
                    </v-badge>
                </v-btn>
                {{ price | currency }}
            </v-list-item-action>
        </v-list-item>
        <v-divider inset></v-divider>
    </div>
</template>

<script>

    import {ADD_TO_CART} from '@/store/actions/cart'

    export default {
        name: 'Reward',
        data: () => ({}),
        props: {
            id: {
                type: Number,
            },
            image: {
                type: String,
            },
            name: {
                type: String,
            },
            description: {
                type: String,
            },
            price: {
                type: Number,
            },
        },
        computed: {
            badgeShow() {
                return this.cartCount > 0
            },
            cartCount() {
                return this.$store.getters.getCartRewardCount(this.id)
            },
        },
        methods: {
            addToOrder() {
                this.$store.dispatch(ADD_TO_CART, {quantity: 1, id: this.id});
            },
            detail() {
                this.$router.push({name: 'RewardDetail', params: {id: this.id}});
            },
            imagePath(img) {
                return process.env.VUE_APP_IMAGE_PATH + 'small/' + img;
            }

        }
    };
</script>
