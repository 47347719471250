<template>
  <v-container>
    <v-row v-if="loaded">
      <v-col cols="12" sm="12" class="pb-0 text-center">
        <h1 class="heading text-truncate">{{ profile.name }}</h1>
        <span class="overline">aktuální stav pěněženky</span><br/>
        <span class="display-2">{{ profile.balance | currency }}</span>
      </v-col>
        <v-col v-if="isBalanceToExpire" cols="12" sm="12" class="pb-0 text-center">
            <v-alert color="error" dark v-html="expirationText"></v-alert>
        </v-col>
      <v-col  v-if="!needToGet"  cols="12" sm="12" class="pb-2 text-center">
        <span class="">Včetně již dosažané čtvrtletní odměny</span><br/>
        <span class="">za období <span class="font-weight-bold">{{ periodText }}</span> ve výši <span class="font-weight-bold pink--text"> {{ reward | currency }}</span></span><br/>
        za odebraných <span class="font-weight-bold">{{ profile.current_quantity }}</span> barev
      </v-col>
      <v-col cols="12" sm="12" class="pb-4 text-center">
        <div v-if="needToGet" v-html="needToGet" class="pb-4 pink--text"></div>
        <div v-html="rewardText"></div>
      </v-col>
      <v-col cols="12" sm="12" class="px-8">
        <v-btn to="/rewards" block dark x-large>Vybrat odměnu</v-btn>
        <v-btn to="/transactions" block text x-large>Přehled transakcí</v-btn>
        <install></install>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="12" class="pb-0 text-center">
        <span class="display-2">Načítám...</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Install from "@/components/Install";
import {USER_REQUEST} from "@/store/actions/user";

export default {
  name: 'Home',
  components: {
    Install
  },
  mounted() {
    this.updateProfile();
  },
  data: () => ({
    loaded: false,
    reward: "",
    needToGet: "",
    nextRewards: "<span class=\"overline\">Pro navýšení čtvrtletní odměny:</span><br />",
  }),
  computed: {
    ...mapState({
      status: state => state.user.status,
    }),
    ...mapGetters({
      profile: 'getProfile',
    }),
    points() {
      return this.profile.group.points === undefined ? null : this.profile.group.points
    },
    periodText() {
      const d = new Date();
      let  quarter = Math.floor((d.getMonth() + 3) / 3);
      let firstMonthQuarter = (quarter * 3) - 2;
        return firstMonthQuarter + '-' + (firstMonthQuarter + 2) + '/' + d.getFullYear();
    },
    rewardText() {
      return this.nextRewards;
    },
      isBalanceToExpire() {
          return this.profile.expiration?.balance > 0;
      },
      expirationText() {
          let date = new Date(this.profile.expiration.at).toLocaleDateString("cs-CZ", { year: 'numeric', month: 'numeric', day: 'numeric' }).replace(/\s/g, '');
          let text = "dne " + date + " vyexpiruje " + this.profile.expiration.balance + ' Kč';
          text += "<br />dobitých do peněženky v roce " + this.profile.expiration.year;
          return text;
      }
    
  },
  methods: {
    ...mapActions({
      updateProfile: USER_REQUEST
    }),
    setRewards() {
      this.nextRewards = '';
      if (this.profile.current_quantity < this.points) {
        this.reward = 0;
        this.needToGet = '<span class="text-body-1">zbývá odebrat <span class="font-weight-medium grey--text text--darken-3">' + (this.points - this.profile.current_quantity) + ' barev</span> pro zachování členství.</span>';
      } else {
        this.profile.group.rewards.every((reward) => {
          if (this.profile.current_quantity >= reward.points) {
            this.reward = reward.key;
            return this.profile.current_quantity >= reward.points;
          }
        });
      }
      this.profile.group.rewards.every((reward) => {
        if (this.profile.current_quantity < reward.points) {
          this.nextRewards = this.nextRewards + '<span class="text-body-2 grey--text text--darken-1"> na ' + '<span class="font-weight-medium grey--text text--darken-3">' + reward.key + ' Kč</span> zbývá odebrat <span class="font-weight-medium grey--text text--darken-3">' + (reward.points - this.profile.current_quantity) + ' barev</span></span><br />';
        }
        return true;
      });
    },
  },
  watch: {
    status (newValue) {
      if (newValue === 'success')
        this.loaded = true;
        this.setRewards();
    }
  }
};
</script>
