/* eslint-disable promise/param-names */
import {AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_FORGOT, AUTH_RESET, AUTH_SIGNUP} from '../actions/auth'
import {USER_REQUEST} from '../actions/user'
import apiCall from '@/utils/api'
import axios from "axios";
import {END_LOADER, START_LOADER} from "../actions/app";

const state = {
    token: localStorage.getItem('user-token') || '',
    status: '',
    hasLoadedOnce: false,
}

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
}

const actions = {
    [AUTH_REQUEST]: ({commit, dispatch}, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            dispatch(START_LOADER);
            apiCall({url: 'auth', data: user, method: 'POST'})
                .then(resp => {
                    localStorage.setItem('user-token', resp.access_token)
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.access_token
                    commit(AUTH_SUCCESS, resp)
                    dispatch(USER_REQUEST)
                    resolve(resp)
                })
                .catch(err => {
                    commit(AUTH_ERROR, err)
                    localStorage.removeItem('user-token')
                    reject(err)
                }).finally(() => {
                dispatch(END_LOADER)
            })
        })
    },
    [AUTH_SIGNUP]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            dispatch(START_LOADER);
            apiCall({url: 'sign-up', data: payload, method: 'POST'})
                .then(resp => {
                    // localStorage.setItem('user-token', resp.access_token)
                    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.access_token
                    // commit(AUTH_SUCCESS, resp)
                    // dispatch(USER_REQUEST)
                    resolve(resp)
                })
                .catch(err => {
                    // commit(AUTH_ERROR, err)
                    // localStorage.removeItem('user-token')
                    reject(err)
                }).finally(() => {
                dispatch(END_LOADER)
            })
        })
    },
    [AUTH_FORGOT]: ({commit, dispatch}, user) => {

        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            dispatch(START_LOADER);
            apiCall({url: 'forgot', data: user, method: 'POST'})
                .then(resp => {
                    // localStorage.setItem('user-token', resp.access_token)
                    // commit(AUTH_SUCCESS, resp)
                    // dispatch(USER_REQUEST)
                    resolve(resp)
                })
                .catch(err => {
                    // commit(AUTH_ERROR, err)
                    // localStorage.removeItem('user-token')
                    reject(err)
                }).finally(() => {
                dispatch(END_LOADER)
            })
        })
    },
    [AUTH_RESET]: ({commit, dispatch}, user) => {

        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            dispatch(START_LOADER);
            apiCall({url: 'reset', data: user, method: 'POST'})
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    // commit(AUTH_ERROR, err)
                    // localStorage.removeItem('user-token')
                    reject(err)
                }).finally(() => {
                dispatch(END_LOADER)
            })
        })
    },
    [AUTH_LOGOUT]: ({commit}) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT)
            localStorage.removeItem('user-token')
            localStorage.removeItem('persistant')
            window.localStorage.clear();
            resolve()
        })
    }
}

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success'
        state.token = resp.access_token
        state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error'
        state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = ''
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
